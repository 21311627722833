<template>
	<div class="About">
		<BackButton ref="back" />
		<TextKR kr="김밥에 대해" size="small" ref="title">
			About Kimb.app
		</TextKR>
		<h2>
			Your success is our goal.
		</h2>
		<p>
			Our goal is to provide the most effiscient and modern way to learn to speak Korean by mixing :<br>
			A great app, amazing teachers, a dose of fun and spicy challenges.
		</p>
		<h2>
			Feedbacks
		</h2>
		<p>
			Any ideas, suggestions or bugs you want to tell us about, <a target="_blank" href="mailto:anyong@kimb.app?subject=feedbacks">contact us ✉️</a>
		</p>
		<h2>
			Credits
		</h2>
		<p>
			David Ronai - CEO
		</p>
		<p>
			Clement Pavageau - Art Director
		</p>
	</div>
</template>

<script>
import { gsap, Quad, TimelineMax } from 'gsap/all'

export default {
	name: 'About',
	methods: {
		transitionIn () {
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.$el.querySelectorAll('p,h2'), 0.5, { opacity: 0, x: -50 }, {
				opacity: 1,
				x: 0,
				ease: Quad.easeOut,
				duration: 0.5
			}, 0.1)
		},
		transitionOut (cb) {
			this.$refs.title.hide()
			this.$refs.back.hide()
			gsap.to(this.$refs.title, { opacity: 0, y: -30, ease: Quad.easeOut })
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerTo(this.$el.querySelectorAll('p,h2'), 0.5, { opacity: 0, x: 80, ease: Quad.easeOut }, 0.1, 0, cb)
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>

<style lang="stylus" scoped>
.About
	color #000
	padding 0 20px
	p
		font-size 14px
		line-height 20px
	a
		color #000
	h2
		margin-top 40px
</style>
